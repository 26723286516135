<template>
  <div id="sub-nav">
    <ul class="sub-nav">
      <li
        v-for="(item, index) in navList"
        :key="index"
        @click="handleClick(index, item)"
      >
        <router-link
          v-if="isRouter"
          :to="{ name: item.component }"
          :class="{ active: activeIndex === index }"
          >{{ item.name }}</router-link
        >
        <div
          v-else
          class="link-name"
          :class="{ active: activeIndex === index }"
        >
          {{ item.name }}
        </div>
      </li>
      <div
        class="animation"
        :style="{ left: activeIndex * (115 + 40) + 20 + 'px' }"
      ></div>
    </ul>
  </div>
</template>

<script>
export default {
  name: "SubNav",
  props: {
    navList: {
      type: Array,
      default: () => []
    },
    isRouter: {
      type: Boolean,
      default: true
    }
  },
  computed: {
    activeIndex() {
      let { path } = this.$route;
      let index = this.navList.findIndex(o => o.path == path);
      if (index == -1) index = 0;
      return index;
    }
  },
  methods: {
    handleClick(index, item) {
      this.$emit("giveIndex", index, item);
    }
  }
};
</script>

<style lang="scss" scoped>
ul {
  padding: 0;
}
#sub-nav {
  position: absolute;
  bottom: -45px;
  left: 50%;
  margin-left: -620px;
  width: 1240px;
  height: 90px;
  box-shadow: 0 0 4px 0px rgba(114, 114, 114, 0.5);
  background-color: #fff;
  display: flex;
  justify-content: center;
}
.sub-nav {
  color: #333;
  display: flex;
  // margin: 0 auto;
  position: relative;
  li {
    width: 115px;
    height: 90px;
    margin: 0 20px;
    a {
      text-decoration: none;
      display: block;
      width: 100%;
      height: 100%;
      line-height: 90px;
      color: #333;
      font-size: 16px;
      transition: all ease 0.2s;
      position: relative;
    }

    a:hover {
      color: #000;
      background-color: rgb(236, 234, 234);
    }
    a.active {
      // border-bottom: 3px solid #004898;
    }
    // a.active::after {
    //   content: "";
    //   display: block;
    //   width: 100%;
    //   height: 3px;
    //   background-color: #004898;
    //   position: absolute;
    //   bottom: 0;
    //   left: 0;
    //   transition: all ease-in 1s;
    // }
  }
  .animation {
    width: 115px;
    height: 3px;
    background-color: #004898;
    position: absolute;
    bottom: 0;
    left: 20px;
    transition: all ease 0.5s;
  }
}
.link-name {
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
}
</style>
