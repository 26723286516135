<template>
  <div>
    <div v-if="newInfo" class="project-banner">
      <div class="bg-filter"></div>
      <div class="text-wrapper">
        <div>{{ newInfo.title }}</div>
        <div>{{ newInfo.englishName }}</div>
      </div>
      <SubNav :navList="navList" />
    </div>
    <div v-if="newInfo && newInfo.profile" class="foreword">
      {{ newInfo.profile }}
    </div>
    <div v-if="newInfo" class="content-wrapper">
      <div class="bg-filter"></div>
      <div class="wrapper">
        <div class="aside left-logo"></div>
        <div class="main">
          <div class="left">
            <div class="scroll-wrapper" v-html="newInfo.content">
              <!--                          <div-->
              <!--                            class="part"-->
              <!--                            v-for="(item, index) in projects[projectIndex].projectDetails"-->
              <!--                            :key="index"-->
              <!--                          >-->
              <!--                            <div class="serial">Part {{ index + 1 }}</div>-->
              <!--                            <div class="name" @click="switchIndex(index)">-->
              <!--                              <span>{{ item.name }}</span>-->
              <!--                            </div>-->
              <!--                            <div class="content">-->
              <!--                              <div v-for="(cItem, cIndex) in item.content" :key="cIndex">-->
              <!--                                {{ cItem }}-->
              <!--                              </div>-->
              <!--                            </div>-->
              <!--                          </div>-->
            </div>
          </div>
          <div class="right">
            <!--            <ul>-->
            <!--              <li-->
            <!--                v-for="(item, index) in projects[projectIndex].projectDetails"-->
            <!--                :key="index"-->
            <!--                @click="switchIndex(index)"-->
            <!--              >-->
            <!--                <div-->
            <!--                  class="dot"-->
            <!--                  :class="{ active: index == currentIndex }"-->
            <!--                ></div>-->
            <!--                <div class="content-item">{{ item.name }}</div>-->
            <!--              </li>-->
            <!--            </ul>-->
          </div>
        </div>
        <div class="aside right-logo"></div>
      </div>
    </div>
  </div>
</template>

<script>
import SubNav from "@/components/SubNav";
import { getNewInfo } from "@/api/frontArticle";

export default {
  name: "ProjectDetails",
  components: {
    SubNav
  },
  data() {
    return {
      navList: [
        {
          name: "公益项目",
          englishName: "",
          path: "/projects/home-vertical",
          component: "HomeVertical"
        }
        // {
        //   name: "项目故事",
        //   englishName: "PROJECT STORY",
        //   path: "/news/story",
        //   component: "Story"
        // }
      ],
      currentIndex: 0,
      projects: [
        {
          name: "乡村助老员支持计划",
          englishName: "",
          foreword:
            "项目于2016年5月开始，经过6年时间，历经西南、西北、重庆、广东四个试点区域的分区迭代，由长益基金会协同政府部门、长江商学院多位学子、社会各界爱心人士共创。",
          projectDetails: [
            {
              name: "项目介绍",
              content: [
                "本项目针对乡村留守老人日常照顾缺位、情感缺失的问题，项目以老人关怀为核心，依托社区养老设施，通过一村培育一名乡村助老员、带动一批社区志愿者，支持一家县级社会服务机构，构建“乡村助老员+社区志愿者+社会服务机构”三位一体的乡村可持续社区互助养老模式，上门提供养老服务并开展社区公益活动，不断提升留守老人晚年的生活质量。",
                "1.培育一批由中青年人组成的乡村助老员队伍。重点解决乡村留守老人日常照顾缺位、情感缺失的难题，不断提升留守老人晚年的生活质量；",
                "2.搭建村庄助老陪伴、社区公益活动的专业化服务体系。为乡村老人提供助老服务的同时，挖掘、动员村庄内部助老志愿者资源；",
                "3.支持优秀的县级社会服务机构。将乡村助老员专业能力、村庄志愿者资源、政府和社会资源等有效结合起来，实现乡村助老模式持续性发展。",
                "4. 通过价值传递的方法，激活社区活力，助力乡村人才振兴。",
                "截止2023年1月，长益基金会通过企业筹资、腾讯乐捐和哔哩哔哩公益等平台筹款及上海合作交流办对口帮扶，为项目执行提供了超过1200万元资金支持。项目覆盖云南、陕西、重庆、广东、河南、贵州6省140多个村社区项目点，培育乡村助老员150余人，孵化/培育社会服务机构5家，激发社区志愿者3000余名，累计为5000余户计6000余位老人提供超过50万人次的养老服务，开展社区公益活动900余场，开展能力建设培训400余场。",
                "项目入选《中国乡村人才振兴优秀案例》、《公益慈善学园案例馆》、《中国好公益平台》、《无公益，不长江：商学院的社会价值2022》，荣获第七届长江公益奖年度公益项目奖项、中国公益慈善项目大赛养老专题“乐龄杰出项目”奖项、国际中国公益事业大典“最具投资公益项目”奖项。",
                "2019年人民网报道：项目填补了政府管理和社会治理的空白，发展壮大了社工队伍，为村（社区）储备了后备干部。同时，通过党性锻炼和教育培训的陪护人员，在宣传、践行党的政策时更接地气，加强了基层政权建设。",
                "2021年人民日报报道：公益项目应该从平等、赋能的角度来思考，要打造社会企业+公益模式，更要有社会公众、本地乡贤共同参与。除了公益执行体系，还要有筹资传播体系，更要创造出商业模式，将其融入到乡村振兴中，才能应对未富先老这个社会议题，实现共同创造老有所伴、互相关怀的社会。",
                "2022年CCTV中央新影发现之旅频道播出《 纪录东方之深山里的守候》。",
                "2022年项目梳理出“价值传递”的方法论，进行项目影响力规模化。"
              ]
            },
            {
              name: "公益执行体系、价值传递",
              content: [
                "本项目于2016年由长江商学院EMBA26期5班的71位学子和班主任共同发起，6年来在云南省保山市施甸县、云南省红河州河口县、陕西省汉中市镇巴县和佛坪县的61个项目村社区持续为1800多户2000多位农村留守老人上门开展居家养老服务和社区公益活动。项目秉持“以人为本、以村社区为本、以激活本土人才资源为着力点”的策略，运用“赋能当地、在地服务、协同政府、多方融合”的方法，从需求出发，协同当地民政局等政府部门，以老人关怀为核心，依托村社区养老设施，通过孵化社会组织、培育乡村助老员、激发村社区志愿者构成公益执行体系，助力农村养老服务体系建设，缓解留守老人日常照顾缺位、情感缺失的问题。",
                "截至2022年12月，3位乡村助老员被本村选举成为村支书，1位乡村助老员参加项目路演获得新时代文明实践志愿服务项目大赛银奖，并获得“最美志愿者”奖。56位乡村助老员自主进行交流培训、综合能力提升，项目村/社区开始呈现出公益性、互助性、服务性的社区雏形，激发社区志愿者2000余名，形成自下而上的积极氛围，有助于文明新风建设。",
                "施甸县项目管理人员协同乡村助老员团队，联络本地企业主发展为理事成员，于2022年11月成立“施甸县益苗社会公益事业服务中心”，成长为一个独立的社会组织，继续和长益基金会进行共创。"
              ]
            },
            {
              name: "五社联动、培育手册",
              content: [
                "2021年6月，“碚乡长益-关怀乡村留守老人”项目由上海市长益公益基金会协同重庆市北碚区民政局、长江戈十七组委会共同发起，重庆市北碚区启辰社会工作服务中心（简称“启辰社工”）承接项目执行、并参与共创项目的本土化培育和可持续发展。",
                "项目以关怀老人为核心，培育北碚区4个街镇16个项目村共16位乡村助老员，为480户留守老人提供居家养老服务和社区公益活动，助力农村养老服务体系建设，缓解老人日常照顾缺位、情感缺失的问题，提升老人的获得感和幸福感。",
                "各项目村的乡村助老员初步形成了以项目村为平台、社会组织为载体、社会工作者为支撑、村志愿者为辅助、社会慈善资源为补充的“五社联动”服务机制，从“单一”参与到“多方”融合参与，助力形成共建共治共享的基层社会治理新格局，服务资源多元链接、服务力量多方协作的良好局面。",
                "2022年8月，重庆疫情、高温，旱情、山火叠加，16位启辰社工参与灭火、物资捐赠和疫情防控，累计服务约280小时、2500人次。启辰社工在“2022年度重庆市学雷锋志愿服务先进典型”中荣获“最佳志愿服务组织”。"
              ]
            },
            {
              name: "慈善信托",
              content: [
                "长安国际信托股份有限公司于2020年设立“长安慈—脱贫攻坚关爱农村三留守群体慈善信托”，与上海市长益公益基金会签订项目协议。由长益基金会孵化、培育佳县本土县域社会组织“佳县慈爱社会公益事业服务中心”（简称“慈爱中心”），并由长益基金会指导其执行项目。",
                "项目协同民政部门和妇联，以老人关怀为核心，依托村社区养老设施，培育乡村助老员、激发村社区志愿者，在村社区提供养老服务和社区公益活动，缓解留守老人日常照顾缺位、情感缺失的问题，助力农村养老服务体系建设，链接村社区三留守群体，提升获得感和幸福感。项目覆盖佳县的4个乡镇共10个村社区的300余户受助老人，由10位乡村助老员为老人提供每月每户2-3次的养老服务。项目周期内每年共开展20场主题活动和20场日常活动，上海市长益公益基金会通过线上、线下分批次对各项目点乡村助老员进行公益本土化培育。",
                "项目先后荣获“慈善项目和慈善信托奖”、“2022年度陕西省学雷锋‘四个最美（佳）’先进典型-最佳志愿服务组织”、“五四青标兵集体”、“志愿服务优秀团队”等奖项。项目受到到时任陕西省副省长蒿慧杰的极高评价，长安大学及西北工业大学校到项目点考察、榆林市部分县区到佳县农村养老项目模式考察。",
                "慈爱中心的主任屈红艳被推选为中共陕西省第十四届党代表，获青年“志愿服务优秀个人”奖、“榆林市巾帼建功标兵”奖、第七届“榆林十大最美女性”提名奖、“陕西省红十字会优秀志愿者”。乡村助老员郭小平获“创业模范奖”（店镇人民政府）、李秀娥获“孝老爱亲模范奖”（店镇人民政府）。"
              ]
            },
            {
              name: "多方协作、可持续发展",
              content: [
                "由韶关市委市政府与腾讯公益慈善基金会共同发起“腾爱兴村帮镇”乡村振兴项目，2022年4月在韶关启动，其中上海市长益公益基金会参与共创韶关农村社区互助养老可持续发展示范项目，包括韶关市乡村振兴公益基金会的在地保障和适老化改造、上海市长益公益基金会的养老服务、中和乡村发展促进中心的集体经济，中科院心理研究所提供的老人心理支持，并由清华大学公共管理学院社会创新与乡村振兴中心为智力支持与跟踪研究。同时引入科技助老产品，协助产品落地，通过科技产品助力乡村养老。",
                "截止到2022年12月，项目在韶关市仁化县董塘镇、乳源县桂头镇共35个村社区落地，由35位乡村助老员为1050余户受助老人提供每月2-3次的公益养老服务，已开展社区公益活动50余场，项目管理人员和乡村助老员获得由长益基金会提供/支持的线上、线下本土化培育20余场。",
                "同时，项目与中国老龄事业发展基金会，深圳老龄事业发展基金会和韶关市乡村振兴公益基金会及腾讯微保合作，为乡村养老引入科技向善。已落地腾讯天籁助听器，腾讯银发科技防跌关爱摄像头，腾讯银发守护卡等一系列科技助老产品和长者意外险，通过乡村助老员协助当地政府对有实际需求的老人进行了配发及使用指导。截止2023年2月，完成首批835位受益老人“长者意外险”的投保工作，完成首批100户“隐形护理员-防跌关爱摄像头”，1000户“银发守护卡”的发放安装调试及使用指导工作，完成983户听力筛查、首批38套天籁助听器的验配工作及参与天籁发布会等推广工作。第二期产品落地工作正在积极筹备阶段。"
              ]
            }
          ]
        },
        {
          name: "小红花梦想",
          englishName: "",
          foreword: "",
          projectDetails: [
            {
              name: "爷爷奶奶陪伴计划",
              content: [
                "“小红花梦想·爷爷奶奶陪伴计划”由腾讯公益慈善基金会发起，与上海市长益公益基金会共创，针对乡村孤寡老人日常照顾缺位、情感缺失的问题，计划在一年时间内，为云南、陕西、重庆等地的2000名乡村孤寡老人通过乡村助老员为其提供养老服务和物资支持，使老人生活质量和精神面貌得到改善，提升老人获得感和幸福感。并用互联网思维、以小红花做为广大捐助者的链接，增强短视频反馈和传播，从公众筹资角度助力可持续发展。",
                "项目自2022年11月启动，截止2023年2月底已覆盖云南、陕西、重庆、广东、河南及贵州的10个区县，由148位乡村助老员为36个乡镇/街道的148个村居委的2000户计2279余位受助老人提供了近13000次陪伴服务，并送去了4560余份温暖包。"
              ]
            },
            {
              name: "为老人做听力筛查和科普",
              content: [
                "“小红花梦想·为一万名乡村老人做听力筛查和科普项目”由腾讯公益慈善基金会发起，与上海市长益公益基金会共创，针对乡村老人听力重视度不足、听力损失严重等问题，计划在一年内，为云南、陕西、重庆、河南、广东、贵州等地乡村老人开展听力筛查和科普服务，从观念上提高老年人听力保护意识，早发现、早诊断、早干预、早康复，改善其日常生活，缓解个人、家庭以及社会的压力。",
                "项目自2023年1月份启动至今，目前已开展听力筛查培训6场，已为200余名老人开展听力科普工作、为30余名老人开展听力筛查服务。"
              ]
            }
          ]
        },
        {
          name: "用心聆听·为爱奔跑",
          englishName: "",
          foreword: "",
          projectDetails: [
            {
              name: "项目简介",
              content: [
                "2022年5月上海疫情期间，由长江商学院高远户外俱乐部和上海校友会联合主办的“用心聆听·为爱奔跑”—心理健康复原建设520线上公益马拉松，报名共计1318人，其中长江老师、校友1074人，37所商学院、戈友组织的嘉宾244人。同时，长江商学院高远户外俱乐部携手上海校友会、久爱公益将520线上公益马拉松在运动超级APP-悦跑圈上线，以线上赛的方式号召全社会爱心跑者共同参与，活动报名人数超过10万人。",
                "主办单位：长江商学院高远户外俱乐部、长江商学院上海校友会、久爱公益",
                "支持单位：悦跑圈APP、上海市长益公益基金会、上海市精神卫生中心、思睿明医疗有限公司"
              ]
            },
            {
              name: "心理复原建设项目",
              content: [
                "本项目由上海市精神卫生中心执行，通过本项目，为社会产出一套贴近现实、满足大众心理需求的优质心理课程，并通过与知名心理专家的直播相结合，通过大众媒体宣传，帮助更多的人重获心理健康，同时通过心理志愿者的行为干预，来帮助眼下处于心理困境的社会大众早日回归正常，为复工复产提供健康助力，最终促进社会大众的心理健康认知水平，促进社会和谐发展。",
                "截至2022年12月，项目开发6套心理复原课程并进行大众传播，举行24场知名心理专家的直播，为心理志愿者提供三个月的必要的公益心理干预服务，通过与第三方合作，服务不少于100家中大型企业，累计直播不少于50场，预计直接受益约50万人，间接受益约200万人。"
              ]
            },
            {
              name: "老年人心理关爱服务",
              content: [
                "本项目由上海市长益公益基金会与上海市精神卫生中心、上海市博物馆合作开展。项目旨在为不断满足上海市老年人日益增长的多层次、高品质健康需求，促进实现健康老龄化。内容包括：",
                "1、	心理科普专栏。通过微信、媒体等相结合的形式推广10个围绕老年人的认知障碍、情绪管理、心理健康素养提升和睡眠等问题的科普内容。",
                "2、	老年心理健康讲座。根据老年人的主要健康问题和需求，围绕抑郁症的识别、缓解焦虑、心理健康等方面开发相关的心理健康课程，为上海老龄化程度比较高的社区开展心理健康讲座。",
                "3、	认知障碍筛查。结合上海市认知障碍友好社区开展“认知障碍筛查及科普宣传活动”，覆盖不少于5个社区500名老人。"
              ]
            }
          ]
        },
        {
          name: "科技助老",
          englishName: "",
          foreword: "",
          projectDetails: [
            {
              name: "“科技助老 渝您同行”",
              content: [
                "在“十四五”期间，为积极助力应对人口老龄化，解决养老服务的痛点、难点问题。在重庆市委市政府、中国老龄事业发展基金会指导下，聚焦重庆市加快建设高质量养老服务体系的需要，充分发挥腾讯公司在持续社会价值和科技创新等领域的优势，上海市长益公益基金会充分运用在乡村人才振兴、基层社会治理的经验和能量传递的公益方法，运用腾讯SSV/腾讯银发科技实验室技术能力，围绕机构、社区和居家养老三大场景，落地重庆智慧养老“银发守护”计划的整体探索方案。进一步推动建设重庆智慧型养老服务体系，加快探索适应重庆养老事业发展的新模式、新方法，促进持续社会价值、共同富裕等方面的深度融合和创新发展。",
                "项目自2022年12月启动至今，已在重庆市第一社会福利院、重庆市巫溪县医养结合医院、重庆市渝北区天宫殿街道、北碚区天生街道、水土街道九龙社区等地区开展项目前期调研，并在重庆市渝北区天宫殿街道、北碚区天生街道、水土街道九龙社区开展认知筛查、听力筛查的试点工作。"
              ]
            }
          ]
        },
        {
          name: "关注藏区老人",
          englishName: "CARE FOR THE ELDERLY IN TIBETAN AREAS",
          foreword:
            "(关注藏区老人)基金会聚焦养老服务，为民政工作和扶贫工作提供个性化补充，以项目带动本土参与，以点带面，激发本土能量，最终形成本土资源循环，从而系统性解决社会问题。项目以县域为单位、与县民政局深度合作，培育农村养老社工为留守老人提供养老服务和社区公益活动，促进山村社区发展，构建简单、信任、快乐的人性生态。",
          projectDetails: [
            {
              name: "项目介绍",
              content: [
                "人口老龄化已经成为我国严峻的社会问题，根据全国老龄办发布的数据，2000年至2018年间，我国60岁及以上老年人口从1.26亿人增加到2.49亿人，老年人口占总人口的比重从10.2%上升至17.9%，而且未来一段时间，老龄化程度将持续加深，无论在城市还是农村，老年人口都是持续增长，对养老服务的需求也是日渐迫切。",
                "人口老龄化已经成为我国严峻的社会问题，根据全国老龄办发布的数据，2000年至2018年间，我国60岁及以上老年人口从1.26亿人增加到2.49亿人，老年人口占总人口的比重从10.2%上升至17.9%，而且未来一段时间，老龄化程度将持续加深，无论在城市还是农村，老年人口都是持续增长，对养老服务的需求也是日渐迫切。",
                "人口老龄化已经成为我国严峻的社会问题，根据全国老龄办发布的数据，2000年至2018年间，我国60岁及以上老年人口从1.26亿人增加到2.49亿人，老年人口占总人口的比重从10.2%上升至17.9%，而且未来一段时间，老龄化程度将持续加深，无论在城市还是农村，老年人口都是持续增长，对养老服务的需求也是日渐迫切。"
              ]
            },
            {
              name: "活动开展情况",
              content: [
                "人口老龄化已经成为我国严峻的社会问题，根据全国老龄办发布的数据，2000年至2018年间，我国60岁及以上老年人口从1.26亿人增加到2.49亿人，老年人口占总人口的比重从10.2%上升至17.9%，而且未来一段时间，老龄化程度将持续加深，无论在城市还是农村，老年人口都是持续增长，对养老服务的需求也是日渐迫切。",
                "人口老龄化已经成为我国严峻的社会问题，根据全国老龄办发布的数据，2000年至2018年间，我国60岁及以上老年人口从1.26亿人增加到2.49亿人，老年人口占总人口的比重从10.2%上升至17.9%，而且未来一段时间，老龄化程度将持续加深，无论在城市还是农村，老年人口都是持续增长，对养老服务的需求也是日渐迫切。",
                "人口老龄化已经成为我国严峻的社会问题，根据全国老龄办发布的数据，2000年至2018年间，我国60岁及以上老年人口从1.26亿人增加到2.49亿人，老年人口占总人口的比重从10.2%上升至17.9%，而且未来一段时间，老龄化程度将持续加深，无论在城市还是农村，老年人口都是持续增长，对养老服务的需求也是日渐迫切。"
              ]
            },
            {
              name: "养老服务",
              content: [
                "人口老龄化已经成为我国严峻的社会问题，根据全国老龄办发布的数据，2000年至2018年间，我国60岁及以上老年人口从1.26亿人增加到2.49亿人，老年人口占总人口的比重从10.2%上升至17.9%，而且未来一段时间，老龄化程度将持续加深，无论在城市还是农村，老年人口都是持续增长，对养老服务的需求也是日渐迫切。",
                "人口老龄化已经成为我国严峻的社会问题，根据全国老龄办发布的数据，2000年至2018年间，我国60岁及以上老年人口从1.26亿人增加到2.49亿人，老年人口占总人口的比重从10.2%上升至17.9%，而且未来一段时间，老龄化程度将持续加深，无论在城市还是农村，老年人口都是持续增长，对养老服务的需求也是日渐迫切。",
                "人口老龄化已经成为我国严峻的社会问题，根据全国老龄办发布的数据，2000年至2018年间，我国60岁及以上老年人口从1.26亿人增加到2.49亿人，老年人口占总人口的比重从10.2%上升至17.9%，而且未来一段时间，老龄化程度将持续加深，无论在城市还是农村，老年人口都是持续增长，对养老服务的需求也是日渐迫切。"
              ]
            },
            {
              name: "项目活动",
              content: [
                "人口老龄化已经成为我国严峻的社会问题，根据全国老龄办发布的数据，2000年至2018年间，我国60岁及以上老年人口从1.26亿人增加到2.49亿人，老年人口占总人口的比重从10.2%上升至17.9%，而且未来一段时间，老龄化程度将持续加深，无论在城市还是农村，老年人口都是持续增长，对养老服务的需求也是日渐迫切。",
                "人口老龄化已经成为我国严峻的社会问题，根据全国老龄办发布的数据，2000年至2018年间，我国60岁及以上老年人口从1.26亿人增加到2.49亿人，老年人口占总人口的比重从10.2%上升至17.9%，而且未来一段时间，老龄化程度将持续加深，无论在城市还是农村，老年人口都是持续增长，对养老服务的需求也是日渐迫切。",
                "人口老龄化已经成为我国严峻的社会问题，根据全国老龄办发布的数据，2000年至2018年间，我国60岁及以上老年人口从1.26亿人增加到2.49亿人，老年人口占总人口的比重从10.2%上升至17.9%，而且未来一段时间，老龄化程度将持续加深，无论在城市还是农村，老年人口都是持续增长，对养老服务的需求也是日渐迫切。"
              ]
            },
            {
              name: "项目本地化",
              content: [
                "人口老龄化已经成为我国严峻的社会问题，根据全国老龄办发布的数据，2000年至2018年间，我国60岁及以上老年人口从1.26亿人增加到2.49亿人，老年人口占总人口的比重从10.2%上升至17.9%，而且未来一段时间，老龄化程度将持续加深，无论在城市还是农村，老年人口都是持续增长，对养老服务的需求也是日渐迫切。",
                "人口老龄化已经成为我国严峻的社会问题，根据全国老龄办发布的数据，2000年至2018年间，我国60岁及以上老年人口从1.26亿人增加到2.49亿人，老年人口占总人口的比重从10.2%上升至17.9%，而且未来一段时间，老龄化程度将持续加深，无论在城市还是农村，老年人口都是持续增长，对养老服务的需求也是日渐迫切。",
                "人口老龄化已经成为我国严峻的社会问题，根据全国老龄办发布的数据，2000年至2018年间，我国60岁及以上老年人口从1.26亿人增加到2.49亿人，老年人口占总人口的比重从10.2%上升至17.9%，而且未来一段时间，老龄化程度将持续加深，无论在城市还是农村，老年人口都是持续增长，对养老服务的需求也是日渐迫切。"
              ]
            },
            {
              name: "丧葬帛金",
              content: [
                "人口老龄化已经成为我国严峻的社会问题，根据全国老龄办发布的数据，2000年至2018年间，我国60岁及以上老年人口从1.26亿人增加到2.49亿人，老年人口占总人口的比重从10.2%上升至17.9%，而且未来一段时间，老龄化程度将持续加深，无论在城市还是农村，老年人口都是持续增长，对养老服务的需求也是日渐迫切。",
                "人口老龄化已经成为我国严峻的社会问题，根据全国老龄办发布的数据，2000年至2018年间，我国60岁及以上老年人口从1.26亿人增加到2.49亿人，老年人口占总人口的比重从10.2%上升至17.9%，而且未来一段时间，老龄化程度将持续加深，无论在城市还是农村，老年人口都是持续增长，对养老服务的需求也是日渐迫切。",
                "人口老龄化已经成为我国严峻的社会问题，根据全国老龄办发布的数据，2000年至2018年间，我国60岁及以上老年人口从1.26亿人增加到2.49亿人，老年人口占总人口的比重从10.2%上升至17.9%，而且未来一段时间，老龄化程度将持续加深，无论在城市还是农村，老年人口都是持续增长，对养老服务的需求也是日渐迫切。"
              ]
            },
            {
              name: "在册老人意外险",
              content: [
                "人口老龄化已经成为我国严峻的社会问题，根据全国老龄办发布的数据，2000年至2018年间，我国60岁及以上老年人口从1.26亿人增加到2.49亿人，老年人口占总人口的比重从10.2%上升至17.9%，而且未来一段时间，老龄化程度将持续加深，无论在城市还是农村，老年人口都是持续增长，对养老服务的需求也是日渐迫切。",
                "人口老龄化已经成为我国严峻的社会问题，根据全国老龄办发布的数据，2000年至2018年间，我国60岁及以上老年人口从1.26亿人增加到2.49亿人，老年人口占总人口的比重从10.2%上升至17.9%，而且未来一段时间，老龄化程度将持续加深，无论在城市还是农村，老年人口都是持续增长，对养老服务的需求也是日渐迫切。",
                "人口老龄化已经成为我国严峻的社会问题，根据全国老龄办发布的数据，2000年至2018年间，我国60岁及以上老年人口从1.26亿人增加到2.49亿人，老年人口占总人口的比重从10.2%上升至17.9%，而且未来一段时间，老龄化程度将持续加深，无论在城市还是农村，老年人口都是持续增长，对养老服务的需求也是日渐迫切。"
              ]
            },
            {
              name: "陪伴人员意外险",
              content: [
                "人口老龄化已经成为我国严峻的社会问题，根据全国老龄办发布的数据，2000年至2018年间，我国60岁及以上老年人口从1.26亿人增加到2.49亿人，老年人口占总人口的比重从10.2%上升至17.9%，而且未来一段时间，老龄化程度将持续加深，无论在城市还是农村，老年人口都是持续增长，对养老服务的需求也是日渐迫切。",
                "人口老龄化已经成为我国严峻的社会问题，根据全国老龄办发布的数据，2000年至2018年间，我国60岁及以上老年人口从1.26亿人增加到2.49亿人，老年人口占总人口的比重从10.2%上升至17.9%，而且未来一段时间，老龄化程度将持续加深，无论在城市还是农村，老年人口都是持续增长，对养老服务的需求也是日渐迫切。",
                "人口老龄化已经成为我国严峻的社会问题，根据全国老龄办发布的数据，2000年至2018年间，我国60岁及以上老年人口从1.26亿人增加到2.49亿人，老年人口占总人口的比重从10.2%上升至17.9%，而且未来一段时间，老龄化程度将持续加深，无论在城市还是农村，老年人口都是持续增长，对养老服务的需求也是日渐迫切。"
              ]
            }
          ]
        }
      ],
      newInfo: null
    };
  },
  methods: {
    switchIndex(index) {
      this.currentIndex = index;
      let scrollWrapper = document.querySelector(".scroll-wrapper");
      scrollWrapper.scrollTop = index * 520;
    },
    handleScroll() {
      let leftEl = document.querySelector(".scroll-wrapper");
      let top = leftEl.scrollTop;
      this.currentIndex = Math.floor(top / 450);
    },
    async getNewsMessage(id) {
      const res = await getNewInfo(id);
      if (res) {
        this.newInfo = res;
      } else {
        this.newInfo = null;
      }
    }
  },
  computed: {
    projectIndex() {
      // console.log(this.$route.params.id);
      let index = Number(this.$route.params.id) - 1;
      return index;
    },
    newId() {
      return this.$route.params.id;
    }
  },
  watch: {
    newId: {
      deep: true,
      immediate: true,
      handler(x) {
        if (x) {
          this.getNewsMessage(x);
        }
      }
    }
  }
};
</script>

<style lang="scss" scoped>
.project-banner {
  background-image: url(../../assets/images/two-banner.jpeg);
  background-position: 50% 35%;
  background-repeat: no-repeat;
  display: flex;
  justify-content: center;
  color: #fff;
  text-align: center;
  width: 80vw;
  min-width: 1240px;
  max-width: 100%;
  height: 23vw;
  min-height: 340px;
  max-height: 430px;
  margin: 0 auto;
  background-size: cover;
  align-items: center;
  position: relative;
  // margin-bottom: 160px;
  // scroll-behavior: smooth;
  // 滤镜
  .bg-filter {
    width: 100%;
    height: 100%;
    position: absolute;
    top: 0;
    left: 0;
    background-color: rgba(37, 163, 246, 0.267);
  }
  // 文字
  .text-wrapper {
    z-index: 10;
    div:first-child {
      font-size: 40px;
      margin-bottom: 30px;
    }
    div:last-child {
      font-size: 20px;
    }
  }
}
.foreword {
  // width: 100%;
  width: 1110px;
  // height: 220px;
  margin: 0 auto;
  padding: 100px 0 0;
  line-height: 30px;
  background-color: #fff;
}
.content-wrapper {
  margin-top: 100px;
  height: 1210px;
  background-image: url(../../assets/images/project-01.jpg);
  // background-color: rgba(33, 136, 204, 0.308);
  background-size: cover;
  display: flex;
  // justify-content: space-between;
  // align-items: center;
  position: relative;
  // filter: grayscale(70%);
  // 滤镜
  .bg-filter {
    width: 100%;
    height: 100%;
    position: absolute;
    top: 0;
    left: 0;
    background-color: rgba(171, 221, 255, 0.794);
    filter: brightness(0.7);
    // z-index: -1;
  }

  .wrapper {
    width: 100%;
    height: 900px;
    // background-color: #fff;
    display: flex;
    position: absolute;
    margin-top: 155px;
    .aside {
      flex: 1 1 auto;
      height: 100%;
      background-color: rgba($color: #fff, $alpha: 0.8);
    }
    .left-logo {
      background-image: url("../../assets/images/logo-bg.png");
      background-size: 400px;
      background-position: 300% 50%;
    }
    .right-logo {
      background-image: url("../../assets/images/logo-bg.png");
      background-size: 400px;
      background-position: -190% 50%;
    }

    .main {
      flex: 0 0 1240px;
      // width: 1240px;
      height: 900px;
      margin: 0 auto;
      // border: 1px solid red;
      display: flex;
      justify-content: space-between;

      .left {
        width: 960px;
        height: 100%;
        padding: 70px;
        background-color: rgba($color: #fff, $alpha: 0.8);

        // chrome safari 隐藏滚动条

        .scroll-wrapper::-webkit-scrollbar {
          display: none;
        }
        .scroll-wrapper {
          // IE 隐藏滚动条
          -ms-overflow-style: none;
          // firefox 隐藏滚动条
          scrollbar-width: none;
          scroll-behavior: smooth;
          width: 100%;
          height: 100%;
          overflow: scroll;
          line-height: 30px;
        }
        .part {
          margin-bottom: 70px;
          .serial {
            // line-height: 45px;
            padding: 0 10px;
            font-size: 24px;
            // text-align: end;
            text-align: right;
            margin-bottom: 20px;
            font-style: italic;
          }
          .name {
            font-size: 30px;
            margin-bottom: 60px;
            span {
              cursor: pointer;
              border-bottom: 1px solid #000;
            }
            span:hover {
              border-bottom: 3px solid #004898;
            }
          }
          .content div {
            margin-bottom: 24px;
          }
        }
      }
      .right {
        width: 240px;
        height: 100%;
        background-color: rgba($color: #fff, $alpha: 0.8);

        display: flex;
        justify-content: center;
        align-items: center;
        ul {
          padding: 0;
        }
        li {
          cursor: pointer;
          height: 60px;
          line-height: 60px;
          display: flex;
          align-items: center;
          .dot {
            width: 10px;
            height: 10px;
            border-radius: 5px;
            background-color: #5cabfb;
            margin-right: 40px;
          }
          .dot.active {
            background-color: #fc8600;
          }
          .content-item:hover {
            text-decoration: underline;
          }
        }
      }
    }
  }
}
.foreword + .content-wrapper {
  margin-top: 50px;
}
</style>
